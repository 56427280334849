// The locale our app first shows
const defaultLocale = "en";
// The active locale
let locale;
// Gets filled with active locale translations
let translations = {
  "en":{
    "hero-1":"My name is",
    "hero-2":"I'm the developer you need!",
    "hero-3":"Know more",
    "about-1":"ABOUT ME",
    "about-2":"I graduated with a bachelor of engineering in automated production in april 2022. My background is more in automation but throughout my last semester in school I found out that I liked web development a lot with my end of study project which was to build a virtual wardrobe. To do so we had to make a website and a mobile application. I think that's when I realised that this is a career I want to pursue. So, since I did not learn the basics in class, I took an online class and made several projects in React.js with node.js as a backend.",
    "about-3":"I learn a lot during my end of study project but we learn on the job and I did not think this was optimal. My plans for the future are to continue making projects and eventually, find a job as a web developer.",
    "about-4":"View Resume",
    "about-5":"assets/CV_LUCASPIERRU_english.pdf",
    "projet1-1":"Background Generator",
    "projet1-2":"This project allows you to create a background with a color gradient with the help of the two color pickers. It also allows you to randomize those two colors by clicking on the randomize button. Finally, it displays the css line of code with the rgb colors.",
    "projet2-1":"CRWN Clothing",
    "projet2-2":"This is clothing store built with React.js where you can do everything that you usually do on a clothing store. There are categories of clothes, you can add clothes to the cart and even checkout. You can also authenticate on the website with an email and password or with Google. The authentication was done with firebase. For checkout, payment is handle with Stripe's serverless functions.",
    "contact":"lucaspierru7@gmail.com",
    "projet3-1":"SmartBrain",
    "projet3-2":"This React.js project allows you to detect a face in an image with help of the " + `<a href="https://www.clarifai.com/"/>Clarifai</a>` + " API. Once you get on the app you will have to register or sign in. The information you give will be sent to the backend. The backend server was made with node.js and express.js. The backend is linked to a postgreSQL database. The application is deployed on Heroku.",
    "see-live":"See Live",
    "source-code-1":"Frontend Source Code",
    "source-code-2":"Backend Source Code"
  },
  "fr":{
    "hero-1":"Je m'appelle",
    "hero-2":"Le développeur dont vous avez besoin !",
    "hero-3":"En savoir plus",
    "about-1":"À PROPOS",
    "about-2":"Je suis diplômé d'un baccalauréat en génie de la production automatisée depuis avril 2022. Mes compétences sont plus en automatisation mais au cours de ma dernière session d'école, j'ai découvert que j'aimé le developpement web grâce à mon projet de fin d'études qui consistait à créer une garde robe virtuelle. Pour réaliser ce projet, nous avons dû créer un site web et une application mobile. Je pense que c'est lors de ce projet que j'ai réalisé que c'est une carrière que je voulais poursuivre. Comme je n'avais pas vraiment appris les bases en classe, j'ai pris un cours en ligne et ai réalisé plusieurs projets avec React.js et avec node.js comme backend.",
    "about-3":"J'ai beaucoup appris lors de mon projet de fin d'études mais nous avons dû apprendre sur le tas et je n'ai pas trouvé cela optimal. Mes plans pour le future sont de continuer à réaliser des projets et, éventuellement, trouver un emploi en tant que développeur web.",
    "about-4":"Voir mon CV",
    "about-5":"assets/CV_LUCASPIERRU.pdf",
    "projet1-1":"Générateur de fond d'écran",
    "projet1-2":"Ce projet vous permet de générer un fond d'écran avec dégradé de couleur avec l'aide de deux palettes de couleurs. Vous pouvez également générer aléatoirement les deux couleurs en cliquant sur un bouton. Enfin, on affiche également la ligne de code css avec les couleur rgb.",
    "projet2-1":"CRWN Clothing",
    "projet2-2":"Ce projet est un magasin de vêtements en ligne réalisé avec React.js. Vous avez differentes catégories de vêtements et vous pouvez ajouter les vêtements dans votre panier ainsi que confirmer la commande. Vous pouvez aussi vous authentifier avec un e-mail et mot de passe ou bien avec votre compte Google. L'authentification a été faite avec firebase. Pour les paiements, on gère cela avec Stripe.",
    "contact":"lucaspierru7@gmail.com",
    "projet3-1":"SmartBrain",
    "projet3-2":"Ce projet React.js vous permet de détecter le visage d'une image en entrant le lien de celle -ci et avec l'aide de l'API " + `<a href="https://www.clarifai.com/"/>Clarifai</a>` + ". Une fois sur l'application vous devez vous enregistrer ou vous connecter. Les informations que vous donner seront envoyées au serveur node.js et express.js. Le serveur est connecté à une base de données postgreSQL. L'application est déployée sur Heroku.",
    "see-live":"Voir Site Web",
    "source-code-1":"Code Source Frontend",
    "source-code-2":"Code Source Serveur"
  }
};
let translate;
// When the page content is ready...
document.addEventListener("DOMContentLoaded", () => {
  // Translate the page to the default locale
  setLocale(defaultLocale);
});
// Load translations for the given locale and translate
// the page to this locale
function setLocale(newLocale) {
  if (newLocale === locale) return;
  const newTranslations = translations[newLocale];
  const btn = document.getElementById("btn");
  locale = newLocale;
  if (locale === "en") {
    btn.innerText = "Français";
  } else {
    btn.innerText = "English";
  }
  translate = newTranslations;
  translatePage();
  return locale;
}
// Replace the inner text of each element that has a
// data-i18n-key attribute with the translation corresponding
// to its data-i18n-key
function translatePage() {
  document
    .querySelectorAll("[data-i18n-key]")
    .forEach(translateElement);
}
// Replace the inner text of the given HTML element
// with the translation in the active locale,
// corresponding to the element's data-i18n-key
function translateElement(element) {
  const key = element.getAttribute("data-i18n-key");
  const translation = translate[key];
  if (key==="about-4") {
    element.setAttribute("href", translate["about-5"])
  }
  element.innerHTML = translation;
}

// When the page content is ready
document.addEventListener("DOMContentLoaded", () => {
  setLocale(defaultLocale);
});
// Whenever the user selects a new locale, we
// load the locale's translations and update
// the page

function buttonLanguage() {
  const btn = document.getElementById("btn");
  if (btn.innerText === "Français") {
    setLocale("fr");
  } else {
    setLocale("en");
  }
}